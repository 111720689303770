/* common */
.hidden {
  height: 100%;
  min-height: 100%;
  overflow: hidden !important;
  touch-action: none;
}
.ellipsis {
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.layer_wrap {
  position: fixed;
  display: none;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

/* 센터 */
.center {
  width: 100%;
  max-width: 1480px;
  margin: 0 auto;
  @media only screen and (max-width: 960px) {
    max-width: 100%;
  }
}

/* 필수항목 */
.required {
  color: #ff3b3b;
  margin-left: 3px;
}

/* 로그인, 회원가입 */
.title_wrap {
  margin-top: 80px;
  text-align: center;
  color: #222;
}
.title_wrap h2 {
  font-size: 2rem;
  line-height: 36px;
}
.title_wrap p {
  margin-top: 20px;
  font-size: 1.125rem;
  line-height: 27px;
}

@media only screen and (max-width: 960px) {
  .title_wrap {
    margin-top: 40px;
  }
  .title_wrap h2 {
    font-size: 1.5rem;
  }

  .title_wrap p {
    font-size: 0.938rem;
    line-height: 22.5px;
  }
}

/* 유효성 에러 메시지 */
.error_msg {
  margin-top: 8px;
  color: #ff2727 !important;
  font-size: 0.75rem;
  line-height: 18px;
}

/* 스와이퍼 네비게이션 삭제 */
.swiper-button-prev,
.swiper-button-next {
  content: none;
}

/* 데이터 없을 경우 */
@media only screen and (min-width: 961px) {
  .noList {
    & p:first-child {
      color: #888;
      font-size: 1.125rem;
      line-height: 27px;
      font-weight: 500;
    }
    & p:nth-child(2) {
      margin-top: 10px;
      color: #888;
      font-size: 1rem;
      line-height: 24px;
    }
    padding: 150px 0;
    border-bottom: 1px solid #ddd;
    text-align: center;
  }
}
@media only screen and (max-width: 960px) {
  .noList {
    padding: 150px 0;
    border-bottom: 1px solid #ddd;
    text-align: center;
    color: #888;
    & p:first-child {
      color: #888;
      font-size: 1.125rem;
      line-height: 25px;
    }
    & p:nth-child(2) {
      margin-top: 10px;
      color: #888;
      font-size: 0.875rem;
      line-height: 20px;
    }
  }
}

select {
  display: block;
  width: 100%;
  padding: 10px 20px;
  border-radius: 0;
  -webkit-appearance:none;
  -moz-appearance:none;
  appearance:none;
  background: url(/images/icon/arr_select.png) no-repeat right 15px center;
  background-size: 12px;
  outline: none;
  &::-ms-expand{
      display:none;
  }
  border: 1px solid #ccc;
  color: #222;
  font-size: 1rem;
  line-height: 24px;
  @media only screen and (max-width: 960px) {
    background: url(/images/m/arr_select.png) no-repeat right 15px center;
    background-size: 12px;
  }
  &:required:invalid {
    color: #999;
    & option {
      color: #222;
    }
  }
}

// 체크박스
.checks {
  display: flex;
  align-items: center;
  position: relative;
  margin-right:10px;
  & input[type="checkbox"], input[type="radio"] {
    position: absolute;
    width:1px;
    height:1px;
    padding:0;
    margin:-1px;
    overflow:hidden;
    clip:rect(0,0,0,0);
    border:0;
  }
  & input[type="checkbox"] + label, input[type="radio"] + label {
    display:inline-flex;
    align-items: center;
    position:relative;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    &::before{
      content:'';
      display:inline-block;
      width: 20px;
      height:20px;
      line-height:20px;
      margin: 0 10px 0 0;
      text-align:center;
      vertical-align:middle;
      background-color: #fff;
      background-image: url(/images/ico_unchecked.png);
      background-repeat: no-repeat;
      background-position: left 3px bottom 4px;
      background-size: 11px auto;
      border:1px solid #ccc;
      border-radius:50%;
      @media only screen and (max-width: 960px) {
        background-image: url(/images/m/ico_unchecked.png);
        background-size: 12px auto;
      }
    }
  }
  & input[type="checkbox"]:checked + label:before, .checks input[type="radio"]:checked + label:before{
    background: url(/images/ico_check_w.png);
    border: 1px solid #1EA0FA;
    @media only screen and (max-width: 960px) {
      background-image: url(/images/m/ico_check_w.png);
    }
  }
}

.full_check {
  & input[type="checkbox"] + label:before, input[type="radio"] + label:before {
    width:24px;
    height:24px;
    line-height:24px;
    background:#fff url(/images/ico_unchecked.png) no-repeat center;
    background-size: 12px 10px;
  }
  & input[type="checkbox"]:checked + label:before {
    background: #1EA0FA url(/images/ico_check_w.png) no-repeat center;
    background-size: 12px auto;
  }
  @media only screen and (max-width: 960px) {
    & inpu0-\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\t[type="checkbox"] + label:before, input[type="radio"] + label:before{
      margin:-2px 10px 0 0;
      flex: 1 1 24px;
      background:#fff url(/images/m/ico_unchecked.png) no-repeat center;
      background-size: 12px auto;
    }
    & input[type="checkbox"]:checked + label:before {
      background: #1EA0FA url(/images/m/ico_check_w.png) no-repeat center;
      background-size: 12px auto;
    }
  }
}


input[type="text"], input[type="password"], input[type="number"], textarea {
  width: 100%;
  padding: 10px 20px;
  line-height: 24px;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 0;
  color: #000;
  outline: none;

  &::placeholder {
    line-height: 24px;
    font-size: 1rem;
    color: #999;
  }

  &:focus {
    border: 1px solid #1EA0FA;
  }

  @media only screen and (max-width: 960px) {
    padding: 11px 14px;
    line-height: 23px;
    font-size: 0.938rem;
  
    &::placeholder {
      line-height: 23px;
      font-size: 0.938rem;
      color: #999;
    }
    @supports (-webkit-overflow-scrolling: touch) {
      font-size: 16px !important;
    }
  }
}

input:disabled {
  background: #f6f6f6;
  color: #999;
}

textarea {
  resize: none;
  &::-webkit-scrollbar {
    position: absolute;
    right: 0;
    top: 0;
    width: 4px;
    border-radius: 15px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #888B9D;
  }
  &::-webkit-scrollbar-track {
    background-color: #DDDDDD;
  }
}

// input radios 박스
.radios {
  position: relative;
  margin-right:10px;

  & input[type="radio"] {
    position: absolute;
    width:1px;
    height:1px;
    padding:0;
    margin:-1px;
    overflow:hidden;
    clip:rect(0,0,0,0);
    border:0
  }

  & input[type="radio"] + label {
    display:inline-block;
    position:relative;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none
  }

  & input[type="radio"] + label:before {
    content:'';
    display:inline-block;
    width:18px;
    height:18px;
    line-height:18px;
    margin:-2px 10px 0 0;
    text-align:center; 
    vertical-align:middle;
    background:#fff;
    border:1px solid #ccc;
    border-radius:50%;
  }

  & input[type="radio"]:checked + label:before{
    background:#fff url(/images/icon/radio_circle.png) no-repeat 3px 3px;
    border:1px solid #1EA0FA;
    @media only screen and (max-width: 960px) {
      background:#fff url(/images/m/radio_circle.png) no-repeat center 3px 3px;
      background-size: 10px 10px;
    }
  }
}

/* 페이지 메인 타이틀(게시판, 마이페이지, 장바구니 등) */
@media only screen and (min-width: 961px) {
  .board_wrap {
    padding: 100px 0;
    width: 100%;
    max-width: 1480px;
    margin: 0 auto;
  }
  .wrap_title {
    padding-bottom: 20px;
    border-bottom: 2px solid #555;
  }  

  .wrap_title h2 {
    font-size: 2rem;
    color: #222;
    font-weight: 400;
    line-height: 48px;
  }

  .wrap_title h2 span {
    margin-left: 40px;
    font-size: 1rem;
    color: #515355;
    line-height: 24px;
    font-weight: 400;
  }

  .mypage_title {
    margin-bottom: 30px;
    border-bottom: none;
    & h2 {
      font-size: 1.5rem;
      line-height: 36px;
      color: #000;
      font-weight: 400;
    }
  }

  .list_nav {
    display: flex;
    max-width: 1480px;
    padding-top: 40px;
    margin-top: 60px;
    border-top: 1px solid #DDDDDD;

    & div {
      display: flex;
      align-items: center;
      width: calc((100% - 70px) / 2);

      & a, & > div {
        display: flex;
        align-items: center;
      }
      
      & p {
        width: 280px;
        font-size: 1.125rem;
        color: #222;
        line-height: 27px;
      }

      & span {
        display: inline-block;
        content: "";
        width: 30px;
        height: 20px;
        text-indent: -9999px;
      }

      &.list_prev {
        & span {
          margin-right: 31px;
          background: url(/images/icon/prev_list.png) no-repeat;
        }
      }

      &.list_next {
        justify-content: flex-end;
        & p {
          text-align: right;
        }
        & span {
          margin-left: 31px;
          background: url(/images/icon/next_list.png) no-repeat;
        }
      }

      & .no_list {
        & span {
          opacity: 0.8;
        }
        & p {
          color: #888;
        }
      }
    }
    & .go_list {
      & img {
        width: 60px;
      }
    }
  }
}

@media only screen and (max-width: 960px) {
  .board_wrap {
    padding: 40px 15px;
    width: 100%;
  }
  
  .wrap_title {
    padding-bottom: 40px;
  }

  .wrap_title h2 {
    font-size: 1.375rem;
    color: #222;
    font-weight: 400;
    line-height: 33px;
    text-align: center;
  }

  .wrap_title h2 span {
    display: block;
    margin-top: 10px;
    font-size: 0.875rem;
    color: #515355;
    line-height: 20px;
    font-weight: 400;
    text-align: center;
  }

  .mypage_title {
    padding: 40px 0;

    & h2 {
      font-size: 1.375rem;
      color: #222;
      font-weight: 400;
      line-height: 33px;
      text-align: center;
    }
  }
  .list_nav {
    display: block;
    position: relative;
    margin-top: 0;
    padding: 0 0 90px 0;
    border-top: 1px solid #515355;

    & > div {
      display: block;
      width: 100%;
      padding: 20px 10px;
      &:first-child {
        border-bottom: 1px solid #E5E5E5;
      }
      &:last-child {
        border-bottom: 1px solid #515355;
      }

      & a, & > div {
        display: flex;
        width: 100%;
        align-items: center;
      }
      
      & p {
        display: -webkit-box;
        overflow: hidden;
        width: 100%;
        text-overflow: ellipsis;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        font-size: 1rem;
        line-height: 24px;
      }

      & span {
        display: inline-block;
        margin-right: 20px;
        word-break: keep-all;
        content: "";
        width: auto;
        height: auto;
        text-indent: inherit;
        color: #999;
        font-size: 0.875rem;
        line-height: 21px;
      }

      &.list_prev {
        & span {
          margin-right: 20px;
          background: none;
        }
      }

      &.list_next {
        justify-content: left;
        & a, & div {
          flex-direction: row-reverse;
          justify-content: left;
        }
        & p {
          text-align: left;
        }
        & span {
          margin-left: 0;
          background: none;
        }
      }

      & .no_list {
        & span {
          opacity: 1;
        }
        & p {
          color: #888;
        }
      }
    }

    & .go_list {
      display: inline-block;
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      & img {
        width: 60px;
      }
    }
  }
}


// -------마이페이지 더보기 버튼
.list_more_btn {
  margin: 60px 0;
  text-align: center;
  & button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 160px;
    height: 50px;
    border-radius: 54px;
    border: 1px solid #ddd;
    background: #fff;
    font-size: 1rem;
    font-weight: 500;
    color: #222;
    & svg {
      margin-left: 6px;
    }
  }
}

@media only screen and (max-width: 960px) {
  .list_more_btn {
    margin: 30px 0;
    & button {
      width: 170px;
      height: 53px;
      border-radius: 63px;
      font-size: 0.875rem;
    }
  }
}
// -----마이페이지 더보기 버튼 끝

// -----마이페이지 하단 가이드
.mypage_guide {
  padding: 25px;
  margin-top: 60px;
  background: #f6f6f6;
  & p {
    display: flex;
    font-size: 0.875rem;
    color: #515355;
    &:not(:first-child) {
      margin-top: 5px;
    }
    &.red {
      color: #ff4545;
    }
    & span {
      line-height: 21px;
      word-break: break-all;
      &:first-child {
        display: inline-block;
        width: 20px;
      }
      &:last-child {
        width: calc(100% - 20px);
      }
      & svg {
        vertical-align: middle;
        margin-top: -2px;
      }
    }
  }

  @media only screen and (max-width: 960px) {
    padding: 30px 15px;
    margin: 0;
    & p {
      display: flex;
      font-size: 0.75rem;
      & span {
        line-height: 21px;
        &:first-child {
          display: inline-block;
          width: 18px;
        }
        &:last-child {
          width: calc(100% - 18px);
        }
        & svg {
          font-size: 18px;
        }
      }
    }
  }
}
// -------마이페이지 하단 가이드 끝

.noArea {
  height: 8px;
  background: #f6f6f6;
}

.custom_scroll {
  overflow-y: auto;
  &::-webkit-scrollbar {
    position: absolute;
    right: 0;
    top: 0;
    width: 4px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #888B9D;
    border-radius: 15px;
  }
  &::-webkit-scrollbar-track {
    background-color: #DDDDDD;
    border-radius: 15px;
  }
}

.custom_scroll_nobg {
  overflow-y: scroll;
  &::-webkit-scrollbar {
    position: absolute;
    right: 0;
    top: 0;
    width: 4px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #ccc;
    border-radius: 6px;
  }
  &::-webkit-scrollbar-track {
    background-color: none;
  }
}

// 스크롤 없애기
.noscroll {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.noscroll::-webkit-scrollbar {
  display: none; /* Chrome, Safari, Opera*/
}

// 셀렉트박스
.selectBox {
  width: 100%;
  height: 100%;
  & .select__control {
    min-height: 100%;
    border: 1px solid #DDDDDD;
    border-radius: 0;
    box-sizing: border-box;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    outline: none !important;
    box-shadow: none;
    &:hover {
      border: 1px solid #DDDDDD;
    }

    // 셀렉트 박스 텍스트
    & .select__value-container {
      padding: 0 15px;
      text-align: left;
      & .select__placeholder {
        font-weight: 400;
        color: #515355;
        font-size: 0.875rem;
      }
      & .select__single-value {
        margin: 0;
        color: #222;
        font-weight: 400;
        line-height: 24px;
      }
      & .select__input {
        padding: 0;
        margin: 0;
        color: #222;
        line-height: 20px;
        & input {
          color: transparent !important;
          text-shadow: 0 0 0 #1EA0FA;
          &:focus {
            outline: none;
          }
        }
      }
    }

    // 셀렉트 박스 화살표
    & .select__indicators {
      & .select__indicator {
        display: inline-block;
        margin-right: 15px;
        background:#fff url(/images/icon/arr_select.png) no-repeat right center;
        & svg {
          display: none;
        }
        @media only screen and (max-width: 960px) {
          width: 14px;
          height: 8px;
          background:#fff url(/images/m/arr_select.png) no-repeat right center;
          background-size: 14px 8px;
        }
      }
      & .select__indicator-separator {
        display: none;
      }
    }
    
    &.select__control--menu-is-open {
      border-radius: 0;
      border: 1px solid #1EA0FA;
      outline: #1EA0FA;
      color: #1EA0FA;
      & .select__placeholder {
        color: #1EA0FA;
      }
      & .select__single-value {
        color: #1EA0FA;
      }
      & .select__indicator {
        background:#fff url(/images/icon/arr_select_close_b.png) no-repeat right center;
        @media only screen and (max-width: 960px) {
          background:#fff url(/images/m/arr_select_close_b.png) no-repeat right center;
          background-size: 14px 8px;
        }
      }
    }
  }

  // 옵션리스트
  & .select__menu {
    top: 42px;
    margin: 0;
    border-radius: 0px;
    box-shadow: none;
    border: 1px solid #1EA0FA;
    text-align: left;
    & .select__option {
      color: #515355;
      padding: 11px 15px;
      line-height: 21px;
      font-size: 0.875rem;
      font-weight: 400;
      &:hover {
        color: #fff;
        background: #1EA0FA;
      }
      &.select__option--is-selected {
        background: none;
        color: #515355;
        &:hover {
          color: #fff;
          background: #1EA0FA;
        }
      }
      &.select__option--is-focused {
        background: none;
        &:hover {
          color: #fff;
          background: #1EA0FA;
        }
      }
    }
    & [class*="MenuList"] {
      padding: 0;
    }
  }
}

.select {
  border: 0;
  height: 33px;

  @media only screen and (max-width: 960px) {
    height: 36px;
  }
  
  & [class*="control"] {
    height: 100%;
    border-radius: 0;
    border: 1px solid #ddd;
    box-sizing: border-box;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    outline: none;
  }
  & [class*="placeholder"], [class*="singleValue"] {
    font-size: 0.875rem;
    color: #888;
    line-height: 21px;
    font-weight: 400;
  }
  & [class*="singleValue"] {
    color: #515355;
  }
  & .css-g1d714-ValueContainer {
    padding: 0 15px;
  }
  & [class*="menu"] {
    padding-top: 0;
    border-radius: 0;
  }
  & [class*="MenuList"] {
    border-radius: 0;
    padding: 0;
  }
  & .css-1okebmr-indicatorSeparator {
      display: none;
  }
  & .css-tlfecz-indicatorContainer {
    display: inline-block;
    width: 9px;
    height: 4.5px;
    margin-right: 15px;
    background:#fff url(/images/icon/arr_select.png) no-repeat right center;
    & svg {
      display: none;
    }
    @media only screen and (max-width: 960px) {
      width: 12px;
      height: 6px;
      background:#fff url(/images/m/arr_select.png) no-repeat right center;
      background-size: cover;
      padding: 0;
    }
  }
  & .css-1gtu0rj-indicatorContainer {
    display: inline-block;
    width: 9px;
    height: 4.5px;
    margin-right: 15px;
    background:#fff url(/images/icon/arr_select_close.png) no-repeat right center;
    & svg {
      display: none;
    }
    @media only screen and (max-width: 960px) {
      width: 12px;
      height: 6px;
      background:#fff url(/images/m/arr_select_close.png) no-repeat right center;
      background-size: cover;
      padding: 0;
    }
  }
  &::-ms-expand {
    display: none;
  }
  span {
    display: none;
  }
}

// tab
.ant-tabs-ink-bar, .ant-tabs-nav-more {
  display: none;
}

// dot 가이드
.dot_guide {
  display: flex;
  text-align: left;
  & span {
      &:first-child {
          display: inline-block;
          width: 20px;
      }
      & svg {
          vertical-align: middle;
          margin-top: -2px;
      }
  }
}


// 게시판
.board_cont {
  line-height: 150%;
}
.board_cont img{
  max-width: 100%;
}
.board_cont b{
  font-weight: 700;
}