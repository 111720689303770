@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@300;400;500;700&display=swap');
* {
  font-family: "Spoqa Han Sans Neo", "Spoqa Han Sans JP", sans-serif;
}

body {
  color: #222;
}

.imp-dialog.customizable.payment-html5_inicis.mobile{
  position: fixed;
  z-index: 1000;
}

.imp-dialog.customizable.payment-html5_inicis.mobile.ios{
  position: fixed;
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video,
button {
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline;
  direction: ltr;
}





a {
  text-decoration: none;
  color: #222;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}
#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
ol,
ul,
li {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

caption {
  display: none;
}

button {
  background: none;
  cursor: pointer;
  outline: none;
  border-radius: 0;
}

input[type="text"],
input[type="password"] {
  outline: none;
  border-radius: 0;
}

i {
  font-style: normal;
}

svg {
  vertical-align: middle;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

img {
  image-rendering: -webkit-optimize-contrast;
}

b {
  font-weight: 500;
}
